"use strict";

(function () {
  var enterModule = require('react-hot-loader').enterModule;
  enterModule && enterModule(module);
})();

/* eslint-disable import/no-dynamic-require */
var plugins = require(process.env.REACT_STATIC_PLUGINS_PATH).default;
var _require = require('./browser'),
  registerPlugins = _require.registerPlugins;
registerPlugins(plugins);
if (typeof document !== 'undefined' && module && module.hot) {
  module.hot.accept(process.env.REACT_STATIC_PLUGINS_PATH, function () {
    registerPlugins(require(process.env.REACT_STATIC_PLUGINS_PATH).default);
  });
}
;
(function () {
  var reactHotLoader = require('react-hot-loader').default;
  var leaveModule = require('react-hot-loader').leaveModule;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(plugins, "plugins", "/Users/tannerlinsley/GitHub/react-static/packages/react-static/src/bootstrapPlugins.js");
  leaveModule(module);
})();
;