"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = onVisible;
(function () {
  var enterModule = require('react-hot-loader').enterModule;
  enterModule && enterModule(module);
})();
if (typeof document !== 'undefined') {
  // Polyfill that shiz!
  require('intersection-observer'); // Do manual polling for intersections every second. This isn't very fast
  // but should handle most edge cases for now

  IntersectionObserver.POLL_INTERVAL = 1000;
}
var list = new Map();
function onVisible(element, callback) {
  if (list.get(element)) {
    return;
  }
  var io = new window.IntersectionObserver(function (entries) {
    entries.forEach(function (entry) {
      // Edge doesn't support isIntersecting. intersectionRatio > 0 works as a fallback
      if (element === entry.target && (entry.isIntersecting || entry.intersectionRatio > 0)) {
        io.unobserve(element);
        io.disconnect();
        callback();
      }
    });
  });
  io.observe(element);
  list.set(element, true);
}
;
(function () {
  var reactHotLoader = require('react-hot-loader').default;
  var leaveModule = require('react-hot-loader').leaveModule;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(list, "list", "/Users/tannerlinsley/GitHub/react-static/packages/react-static/src/browser/utils/Visibility.js");
  reactHotLoader.register(onVisible, "onVisible", "/Users/tannerlinsley/GitHub/react-static/packages/react-static/src/browser/utils/Visibility.js");
  leaveModule(module);
})();
;